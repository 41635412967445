<template>
  <CModal
    class="transfer-order-modal"
    :title="`Transfer / Revoke Order`"
    color="dark"
    size="xl"
    :show.sync="active"
    :closeOnBackdrop="false"
  >
    <div class="alert alert-warning p-2" v-if="!loading && restaurant?.restaurant_postcode && !couriers.length">
      <h4 class="mb-0">⚠️ No couriers are available to service the <strong>{{ restaurant.restaurant_postcode }}</strong> postcode area at this time.</h4>
    </div>

    <div class="alert alert-info p-2">
      <div class="vertical-center flex-gap-2">
        <div class="h1 mb-0">🛈</div>
        <div class="flex-grow-1 lead">
          Subordinate couriers are not listed because direct order assignment is not allowed.
        </div>
      </div>
    </div>

    <div class="alert alert-danger p-2" v-if="isDeliveryDateToCourierInFuture">
      <div class="vertical-center flex-gap-2">
        <div class="h1 flash-it">⚠</div>
        <div class="flex-grow-1 lead">
          The earliest time the order will be assigned to the courier must be
          <strong>{{ new Date(order.roAproxDeliveryDateToCourier).toLocaleString() }}</strong>
          <br />
          You assign the order to the courier about <span class="h5">{{ timeUntilDeliveryToCourier }} ago?</span>
        </div>
      </div>
    </div>

    <CDataTable
      class="table-transfer-order"
      striped
      hover
      :items="couriers"
      :fields="fields"
      :loading="loading"
      :table-filter="{
        label: 'Search:',
        placeholder: 'Search for couriers...',
        external: false,
        lazy: false
      }"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No couriers found!',
      }"
      clickable-rows
      @row-clicked="rowClicked"
    >
      <!-- Check Box -->
      <template #select="{item}">
        <td class="no-print">
          <CInputCheckbox
            v-if="!item.disable"
            :checked="item._selected"
            @update:checked="() => check(item)"
            custom
          />
        </td>
      </template>

      <!-- ID -->
      <template #id="{item}">
        <td>
          #{{ item?.id }}
        </td>
      </template>

      <!-- User Icon -->
      <template #image_icon="{item}">
        <td>
          <CImg
            :src="item.image_icon || item.gravatar"
            class="c-avatar-img"
            style="width: 36px; height: 36px"
            placeholderColor="lightgray"
          />
        </td>
      </template>

      <!-- Name -->
      <template #first_name="{item}">
        <td>
          <!-- The most suitable courier for this order -->
          <div>
            <span v-if="item.suitable" class="h4" v-c-tooltip="{ content: 'The most suitable courier for this order' }">⭐</span>
            <span v-if="item.settings?.is_dispatcher" class="dot bg-secondary" v-c-tooltip="{ content: 'Dispatcher' }">👔</span>
            <span class="text-muted"> #{{ item?.id }}</span>
          </div>
          <div><strong>{{ item.first_name }}</strong></div>
        </td>
      </template>

      <!-- Contact -->
      <template #email="{item}">
        <td>
          <div><CIcon name="cil-at" class="mr-1" />{{ item.email }}</div>
          <div><CIcon name="cil-phone" class="mr-1" />{{ item.mobile }}</div>
        </td>
      </template>

      <!-- Vehicle -->
      <template #vehicle="{item}">
        <td>
          <div v-if="item.vehicle" class="d-flex align-items-center flex-gap-2">
            <MIcon :tag="item.vehicle.mode" />
            <span class="text-uppercase">{{ item.vehicle.license_plate }}</span>
          </div>
        </td>
      </template>

      <!-- Hubs -->
      <template #hubs="{item}">
        <td class="p-0">
          <div class="vertical-center flex-gap-2">
            <div v-for="hub in item.hubs" class="vertical-center">
              <span v-c-tooltip="{ content: hub.backup_priority === null ? 'Primary' : 'Backup ' + (hub.backup_priority) }" class="h4">
                <template v-if="hub.backup_priority == null">🏅</template>
                <template v-else-if="hub.backup_priority == 1">🥇</template>
                <template v-else-if="hub.backup_priority == 2">🥈</template>
                <template v-else>🥉</template>
              </span>
              <small>{{ hub.name }}</small>
            </div>
          </div>
        </td>
      </template>

      <!-- Distance to restaurant -->
      <template #distance_to_restaurant="{item}">
        <td>
          <span v-if="item.distance_to_restaurant != null">
            <span class="text-muted">{{ (item.distance_to_restaurant / 1000).toFixed(2) }}</span>
          </span>
        </td>
      </template>

      <!-- Status -->
      <template #status="{item}">
        <td class="text-center">
          <div class="vertical-center flex-gap-1 text-light">
            <!-- Order Rejected -->
            <span v-if="item.has_rejected_order" class="h4 mt-1" v-c-tooltip="{ content: `Order Rejected (${item.rejection_count})` }">👎</span>

            <!-- Is dispatcher -->
            <span v-if="item.settings?.is_dispatcher" class="dot bg-secondary" v-c-tooltip="{ content: 'Dispatcher' }">👔</span>

            <!-- Is working -->
            <span :class="{ 'dot': true, 'bg-danger': !item.status?.is_working_now, 'bg-success': item.status?.is_working_now }">W</span>

            <!-- On break -->
            <span v-if="item.status?.is_working_now && item.status?.on_break" class="dot bg-warning">B</span>

            <!-- On order -->
            <span v-if="item.status?.on_order" class="dot bg-success">O</span>
          </div>
        </td>
      </template>
    </CDataTable>

    <template #footer class="d-flex p-1">
      <CInputCheckbox custom :checked.sync="includeNonWorking"
        label="Include couriers that are not working now" />

      <CInputCheckbox custom class="mr-auto" :checked.sync="includeRejectedCouriers"
        label="Include couriers who rejected this order" />

      <CButton @click="active = false" color="link">Close</CButton>

      <CButton v-if="courierId != null || dispatcherId != null" color="danger" :disabled="(courierId == null && dispatcherId == null) || selectedCourier != null || submitted" @click="revokeOrder">
        <CSpinner v-if="courierId != null && selectedCourier == null && submitted" size="sm" class="mr-2" />
        <CIcon name="cil-action-undo" class="mt-0" /> Revoke
      </CButton>

      <CButton color="success" :disabled="selectedCourier == null || submitted" @click="transferOrder">
        <CSpinner v-if="selectedCourier != null && submitted" size="sm" class="mr-2" />
        <CIcon name="cil-transfer" class="mt-0" /> Transfer
      </CButton>
    </template>
  </CModal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TransferOrRevokeOrder",
  props: {
    courierId: { default: null, type: Number },
    dispatcherId: { default: null, type: Number },
    order: {
      type: Object, required: true,
      validator: function (value) {
        return 'id' in value;
      }
    },
    restaurant: {
      type: Object, required: true,
      validator: function (value) {
        return 'restaurant_postcode' in value;
      }
    }
  },
  data() {
    return {
      fields: [
        { key: "select", label: "", filter: false, sorter: false, _classes:"no-print", _style: "width: 1%" },
        // { key: "id", label: "ID", _style: "min-width: 60px; width: 60px;" },
        { key: "image_icon", label: "", sorter: false,  _style: "min-width: 60px; width: 60px;" },
        { key: "first_name", label: "Courier", _style: "min-width: 140px;" },
        { key: "email", label: "Contact", _style : "min-width: 180px;" },
        { key: "vehicle", _style : "min-width: 150px;" },
        { key: "hubs", _style: "min-width: 120px;" },
        { key: "distance_to_restaurant", label: "Distance to Rest. (km)", _style: "max-width: 90px;" },
        { key: "status", label: "", _style : "min-width: 100px;" },
      ],

      loading: false,
      submitted: false,

      rawCouriers: [],
      selectedCourier: null,
      includeNonWorking: false,
      includeRejectedCouriers: false
    };
  },

  computed: {
    ...mapGetters(["errors"]),
    active: {
      get: function () { return this.$store.state.transferOrRevokeOrderShow },
      set: function (newValue) { this.$store.state.transferOrRevokeOrderShow = newValue }
    },
    couriers() {
      return this.rawCouriers
        .sort((a, b) => {
          // a için tüm hub'lardaki en düşük backup_priority'yi bul
          const minPriorityA = Math.min(
            ...a.hubs.map(hub => hub.backup_priority === null ? -1 : hub.backup_priority)
          );

          // b için tüm hub'lardaki en düşük backup_priority'yi bul
          const minPriorityB = Math.min(
            ...b.hubs.map(hub => hub.backup_priority === null ? -1 : hub.backup_priority)
          );

          // En düşük backup_priority'ye göre sıralama
          return minPriorityA - minPriorityB;
        })
        .sort((a, b) => b.status?.is_working_now - a.status?.is_working_now)
        .sort((a, b) => (b.suitable ?? false) - (a.suitable ?? false))
        .map(el => {
          const disable = (el.status?.on_order && !this.canHaveMultipleOrders(el))
            || el.status?.on_break
            || (!this.includeNonWorking && !el.status?.is_working_now)
            || (!this.includeRejectedCouriers && el.has_rejected_order);
          return {
            ...el,
            disable,
            _classes: disable ? 'disable-item strikeout' : '',
          };
        })
        .sort((a, b) => (a.has_rejected_order ?? false) - (b.has_rejected_order ?? false))
        .sort((a, b) => a.disable - b.disable);
    },
    isDeliveryDateToCourierInFuture() {
      if (this.order?.roDesiredDelivireyTime == "asap" || !this.order?.roAproxDeliveryDateToCourier) {
        return false;
      }
      return new Date(this.order.roAproxDeliveryDateToCourier) > new Date();
    },
    timeUntilDeliveryToCourier() {
      if (!this.isDeliveryDateToCourierInFuture) {
        return null;
      }
      return this.moment.duration(this.moment(this.order.roAproxDeliveryDateToCourier).diff(this.moment())).humanize();
    },
  },

  watch: {
    active: async function (val) {
      if (val) {
        this.includeNonWorking = false;
        this.includeRejectedCouriers = false;
        this.selectedCourier = null;

        this.loading = true;
        await Promise.all([this.getCouriers()]);
        this.loading = false;
      }
    },
  },

  methods: {
    async getCouriers() {
      this.rawCouriers = []

      var url = new URL(this.$backend.COURIER.ORDERS.ASSIGNABLE_COURIERS.replace("{order_id}", this.order.id)),
        params = {
          'except-ids': [this.courierId, this.dispatcherId].filter(item => item),
          'exclude-subordinates': true,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then(({ data }) => {
          this.rawCouriers = data;
        })
        .catch(() => this.rawCouriers = [])
    },

    rowClicked(item, index, column, e) {
      // INPUT, LABEL ve BUTTON dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'A'].includes(e.target.tagName)) {
        this.check(item)
      }
    },

    check (item) {
      this.$set(item, "_selected", !item._selected);
      // v3
      if (item._selected) {
        // Sadece bir adet item seçilsin.
        if(this.selectedCourier) {
          this.selectedCourier._selected = false;
          this.selectedCourier._classes = this.selectedCourier._classes.replace(" table-selected", "");
        }

        item._classes = item._classes.concat(" table-selected");
        this.selectedCourier = item;
      } else {
        item._classes = item._classes.replace(" table-selected", "");
        this.selectedCourier = null;
      }
    },

    async transferOrder() {
      this.submitted = true

      await this.$axios.post(this.$backend.COURIER.ORDERS.TRANSFER, {
        "courier": this.courierId,
        "dispatcher": this.dispatcherId,
        "new-courier": this.selectedCourier?.id,
        "order-id": this.order.id,
      })
        .then(({ data }) => {
          this.submitted = false
          this.$toast.success(`${data.data.message}`);
          this.active = false;
          this.$emit("transferred");
        })
        .catch(() => {
          this.submitted = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
    },

    async revokeOrder() {
      this.submitted = true

      await this.$axios.post(this.$backend.COURIER.ORDERS.REVOKE, {
        "courier": this.courierId,
        "dispatcher": this.dispatcherId,
        "order-id": this.order.id,
      })
        .then(({ data }) => {
          this.submitted = false
          this.$toast.success(`${data.data.message}`);
          this.active = false;
          this.$emit("transferred");
        })
        .catch((error) => {
          this.submitted = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
    },

    canHaveMultipleOrders(courier) {
      return courier.settings?.can_have_multiple_orders;
      //admin should always assign order to couriers
      // return courier.settings?.can_have_multiple_orders &&
     //   (
     //     this.hasSameRestaurantOrder(courier) ||
     //     this.hasSameCustomerAddressOrder(courier) ||
     //     courier.settings?.is_dispatcher // Allow dispatchers to have multiple orders, even if they have already assigned an order to themselves
     //   );
    },
    hasSameRestaurantOrder(courier) {
      // Check if any active order is from the same restaurant
      return courier.active_orders.some(order => order.restaurant.id === this.restaurant.id);
    },
    hasSameCustomerAddressOrder(courier) {
      // Check if any active order has the same address as the current order
      return courier.active_orders.some(order =>
        order.roAddress === this.order.roAddress
        && order.roCity === this.order.roCity
        && order.roPostal_code === this.order.roPostal_code
        && order.roCountryId === this.order.roCountryId
      );
    }
  },
};
</script>

<style>
.transfer-order-modal .modal-header {
  padding: 0.75rem !important;
}
.transfer-order-modal .modal-footer {
  padding: 0.25rem !important;
}

.table-transfer-order table {
  margin-bottom: 0;
}
.table-transfer-order th,
.table-transfer-order td {
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}
.table-transfer-order th {
  border-top: none;
}

/* Table filter */
.table-transfer-order label.mfe-2 {
  display: none;
}
.table-transfer-order label.mfe-2 + input {
  width: 100% !important;
  font-size: 1rem;
  font-weight: 500;
  color: #f9b115 !important;
}
</style>
